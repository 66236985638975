import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { MotionPlugin } from '@vueuse/motion'
// import VueGtag from 'vue-gtag';

loadFonts()

const app = createApp(App)

app.use(vuetify)
// app.use(VueGtag, {
//   config: { id: 'G-W1GG0XHEDL' }
// })
app.use(router)
app.use(MotionPlugin)
app.mount('#app')




<!-- The responsive design here is shit! I just started learning then and now I don't have the time to fix it yet. If you can, very well then... -->


<template>
  <div class="home">
    <v-app-bar v-if="isMobile" density="compact" elevation="0" color="#222222" style="padding-inline: 20px;">
      <img style="width: 80px; margin-left: -20px;" :src="require('@/assets/tackstry.png')" alt="Logo">
      <p class="custom-font" style="margin-left: -20px;">TACKSTRY</p>
      <v-spacer></v-spacer>
      <button style="text-decoration: underline; font-size: 14px;" @click="scrollToContactBlock">GET IN TOUCH</button>
    </v-app-bar>
    <v-app-bar v-if="isTablet" density="compact" elevation="0" color="#222222" style="padding-inline: 5%;">
      <img style="width: 80px; margin-left: -20px;" :src="require('@/assets/tackstry.png')" alt="Logo">
      <p class="custom-font" style="margin-left: -20px;">TACKSTRY</p>
      <v-spacer></v-spacer>
      <button style="text-decoration: underline; font-size: 16px;" @click="scrollToContactBlock">GET IN TOUCH</button>
    </v-app-bar>
    <v-app-bar v-if="isDesktop" density="compact" elevation="0" color="#222222"
      style="padding: 10px; padding-inline: 100px;">
      <img style="width: 80px;" :src="require('@/assets/tackstry.png')" alt="Logo">
      <p class="custom-font">TACKSTRY</p>
      <v-spacer></v-spacer>
      <button style="text-decoration: underline;" @click="scrollToContactBlock">GET IN TOUCH</button>
    </v-app-bar>



    <div v-if="isMobile" 
      style="height: 70vh; display: flex; flex-direction: column; justify-content: center;">
      <p style="font-size: 40px; padding: 20px;">We believe in the power of<br>innovation<br>to shape a brighter future
      </p>
    </div>
    <div v-if="isTablet" 
      style="height: 80vh; display: flex; flex-direction: column; justify-content: center;">
      <p style="font-size: 60px; padding-inline: 10%;">We believe in the power of<br>innovation<br>to shape a brighter
        future
      </p>
    </div>
    <div v-if="isDesktop" 
      style="height: 75vh; display: flex; flex-direction: column; justify-content: center;">
      <p style="font-size: 96px; padding: 120px;">We believe in the power of<br>innovation<br>to shape a brighter future
      </p>
    </div>

    <div v-if="isMobile" style="display: flex; flex-direction: column; justify-content: center;">
      <button style="font-size: 16px; padding: 20px; text-decoration: underline; text-align: start;"
        @click="scrollToInnovationBlock">
        SCROLL DOWN
      </button>
    </div>
    <div v-if="isTablet" style="display: flex; flex-direction: column; justify-content: center;">
      <button style="font-size: 16px; padding-inline: 10%; text-decoration: underline; text-align: start;"
        @click="scrollToInnovationBlock">
        SCROLL DOWN
      </button>
    </div>
    <div v-if="isDesktop" style="display: flex; flex-direction: column; justify-content: center;">
      <button style="font-size: 16px; padding: 120px; text-decoration: underline; text-align: start;"
        @click="scrollToInnovationBlock">
        SCROLL DOWN
      </button>
    </div>

    <div v-if="isMobile" ref="innovationBlock" 
      style="height: 80vh; display: flex; flex-direction: column; justify-content: center;">
      <p style="font-size: 40px; padding: 20px;">That's why we've created a nexus<br>where<br>Innovation meets
        Integration.</p>
    </div>
    <div v-if="isTablet" ref="innovationBlock" 
      style="height: 80vh; display: flex; flex-direction: column; justify-content: center;">
      <p style="font-size: 60px; padding-inline: 10%;">That's why we've created a nexus<br>where<br>Innovation meets
        Integration.</p>
    </div>
    <div v-if="isDesktop" ref="innovationBlock" 
      style="height: 80vh; display: flex; flex-direction: column; justify-content: center;">
      <p style="font-size: 96px; padding: 120px;">That's why we've created a nexus<br>where<br>Innovation meets
        Integration.</p>
    </div>

    <div v-if="isMobile" style="display: flex; flex-direction: column; justify-content: center;">
      <button style="font-size: 16px; padding: 20px; text-decoration: underline; text-align: start;"
        @click="scrollToServicesBlock">
        SCROLL DOWN
      </button>
    </div>
    <div v-if="isDesktop" style="display: flex; flex-direction: column; justify-content: center;">
      <button style="font-size: 16px; padding: 120px; text-decoration: underline; text-align: start;"
        @click="scrollToServicesBlock">
        SCROLL DOWN
      </button>
    </div>


    <div ref="servicesBlock" v-if="isMobile" 
      style="display: flex; flex-direction: column; justify-content: center; padding-inline: 20px;">
      <p style="font-size: 16px;  padding-top: 120px; text-decoration: underline;">WHAT WE DO</p>
      <p style="padding-top: 50px; font-size: 20px;">We offer a range of services to meet your digital needs. Explore
        how
        we can help you shape a brighter future.</p>

      <div
        style="display: flex; flex-direction: column; border: 1px solid #444; border-radius: 32px; background-color: #292929; padding: 40px; margin-top: 40px;">
        <p style="font-size: 26px;">Software Design and Development</p>
        <p style="font-size: 16px; margin-top: 20px;">
          Unlock your vision with our tailored software design and development services—your innovation, our expertise!
        </p>
        <p style="font-size: 16px; margin-top: 50px; text-decoration: underline;" @click="scrollToContactBlock">LEARN
          MORE
        </p>
      </div>

      <div
        style="display: flex; flex-direction: column; border: 1px solid #444; border-radius: 32px; background-color: #292929;  padding: 40px; margin-top: 40px;">
        <p style="font-size: 26px;">DevOps and Cloud Infastructure</p>
        <p style="font-size: 16px; margin-top: 20px;">
          Experience unparalleled efficiency and flexibility as we optimize your workflows, ensuring rapid
          scalability and continuous innovation. Embrace a seamless future with us!
        </p>
        <p style="font-size: 16px; margin-top: 50px; text-decoration: underline;" @click="scrollToContactBlock">LEARN
          MORE
        </p>
      </div>

      <div
        style="display: flex; flex-direction: column; border: 1px solid #444; border-radius: 32px; background-color: #292929; padding: 40px; margin-top: 40px;">
        <p style="font-size: 26px;">Hardware Design and Building</p>
        <p style="font-size: 16px; margin-top: 20px;">
          Discover the convenience of our comprehensive hardware design and building service ensuring
          a seamless and tailored experience from conception to realization.
        </p>
        <p style="font-size: 16px; margin-top: 50px; text-decoration: underline;" @click="scrollToContactBlock">LEARN
          MORE
        </p>
      </div>

      <div
        style="display: flex; flex-direction: column; border: 1px solid #444; border-radius: 32px; background-color: #292929; padding: 40px; margin-top: 40px;">
        <p style="font-size: 26px;">Process Design and Automation</p>
        <p style="font-size: 16px; margin-top: 20px;">
          Empower your business with seamless process design and automation as a service—unlock productivity, amplify
          growth
        </p>
        <p style="font-size: 16px; margin-top: 50px; text-decoration: underline;" @click="scrollToContactBlock">LEARN
          MORE
        </p>
      </div>

    </div>

    <div ref="servicesBlock" v-if="isTablet" 
      style="height: 80vh; display: flex; flex-direction: column; justify-content: center; padding: 10%; margin-top: 100px;">
      <p style="font-size: 16px;  padding-top: 120px; text-decoration: underline;">WHAT WE DO</p>
      <p style="padding-top: 50px; font-size: 20px;">We offer a range of services to meet your digital needs. Explore
        how
        we can help you shape a brighter future.</p>

      <div style="display: flex;  margin-top: 50px; justify-content: space-between;">

        <div
          style="display: flex; flex: 1; flex-direction: column; border: 1px solid #444; border-radius: 32px; background-color: #292929; padding: 40px;">
          <p style="font-size: 36px;">Software Design and Development</p>
          <p style="font-size: 20px; margin-top: 20px;">
            Unlock your vision with our tailored software design and development services—your innovation, our
            expertise!
          </p>
          <p style="font-size: 16px; margin-top: 50px; text-decoration: underline;" @click="scrollToContactBlock">LEARN
            MORE</p>
        </div>

        <div style="width: 80px;"></div>

        <div
          style="display: flex;  flex: 1; flex-direction: column; border: 1px solid #444; border-radius: 32px; background-color: #292929; padding: 40px; ;">
          <p style="font-size: 36px;">DevOps and Cloud Infastructure</p>
          <p style="font-size: 20px; margin-top: 20px;">
            Experience unparalleled efficiency and flexibility as we optimize your workflows, ensuring rapid scalability
            and continuous innovation. Embrace a seamless future with us!
          </p>
          <p style="font-size: 16px; margin-top: 50px; text-decoration: underline;" @click="scrollToContactBlock">LEARN
            MORE</p>
        </div>

      </div>

      <div style="display: flex; margin-top: 50px; justify-content: space-between;">

        <div
          style="display: flex; flex: 1; flex-direction: column; border: 1px solid #444; border-radius: 32px; background-color: #292929; padding: 40px;">
          <p style="font-size: 36px;">Hardware Design and Building</p>
          <p style="font-size: 20px; margin-top: 20px;">
            Discover the convenience of our comprehensive hardware design and building service ensuring a seamless
            and tailored experience from conception to realization.
          </p>
          <p style="font-size: 16px; margin-top: 50px; text-decoration: underline;" @click="scrollToContactBlock">LEARN
            MORE</p>
        </div>

        <div style="width: 80px;"></div>

        <div
          style="display: flex; flex: 1; flex-direction: column; border: 1px solid #444; border-radius: 32px; background-color: #292929; padding: 40px; ;">
          <p style="font-size: 36px;">Process Design and Automation</p>
          <p style="font-size: 20px; margin-top: 20px;">
            Empower your business with seamless process design and automation as a service—unlock productivity, amplify
            growth
          </p>
          <p style="font-size: 16px; margin-top: 50px; text-decoration: underline;" @click="scrollToContactBlock">LEARN
            MORE</p>
        </div>

      </div>
    </div>

    <div ref="servicesBlock" v-if="isDesktop" 
      style="height: 80vh; display: flex; flex-direction: column; justify-content: center; padding-inline: 120px;">
      <p style="font-size: 16px;  padding-top: 120px; text-decoration: underline;">WHAT WE DO</p>
      <p style="padding-top: 50px; font-size: 20px;">We offer a range of services to meet your digital needs. Explore
        how
        we can help you shape a brighter future.</p>

      <div style="display: flex; margin-top: 50px; justify-content: space-between;">

        <div
          style="display: flex;  flex: 1; flex-direction: column; border: 1px solid #444; border-radius: 32px; background-color: #292929; padding: 40px;">
          <p style="font-size: 36px;">Software Design and Development</p>
          <p style="font-size: 20px; margin-top: 20px;">
            Unlock your vision with our tailored software design and development services—your innovation, our
            expertise!
          </p>
          <p style="font-size: 16px; margin-top: 50px; text-decoration: underline;" @click="scrollToContactBlock">LEARN
            MORE</p>
        </div>

        <div style="width: 80px;"></div>

        <div
          style="display: flex; flex: 1; flex-direction: column; border: 1px solid #444; border-radius: 32px; background-color: #292929; padding: 40px; ;">
          <p style="font-size: 36px;">DevOps and Cloud Infastructure</p>
          <p style="font-size: 20px; margin-top: 20px;">
            Experience unparalleled efficiency and flexibility as we optimize your workflows, ensuring rapid scalability
            and continuous innovation. Embrace a seamless future with us!
          </p>
          <p style="font-size: 16px; margin-top: 50px; text-decoration: underline;" @click="scrollToContactBlock">LEARN
            MORE</p>
        </div>

      </div>


      <div style="display: flex; margin-top: 50px; justify-content: space-between;">

        <div
          style="display: flex; flex-direction: column; flex: 1; border: 1px solid #444; border-radius: 32px; background-color: #292929; padding: 40px;">
          <p style="font-size: 36px;">Hardware Design and Building</p>
          <p style="font-size: 20px; margin-top: 20px;">
            Discover the convenience of our comprehensive hardware design and building service
            ensuring a seamless and tailored experience from conception to realization.
          </p>
          <p style="font-size: 16px; margin-top: 50px; text-decoration: underline;" @click="scrollToContactBlock">LEARN
            MORE</p>
        </div>

        <div style="width: 80px;"></div>

        <div
          style="display: flex; flex-direction: column; flex: 1; border: 1px solid #444; border-radius: 32px; background-color: #292929; padding: 40px; ;">
          <p style="font-size: 36px;">Process Design and Automation</p>
          <p style="font-size: 20px; margin-top: 20px;">
            Empower your business with seamless process design and automation as a service—unlock productivity, amplify
            growth
          </p>
          <p style="font-size: 16px; margin-top: 50px; text-decoration: underline;" @click="scrollToContactBlock">LEARN
            MORE</p>
        </div>

      </div>

    </div>


    <div v-if="isMobile" style="display: flex; flex-direction: column; justify-content: center; margin-top: 200px;">
      <button style="font-size: 16px; padding: 20px; text-decoration: underline; text-align: start;"
        @click="scrollToMissionBlock">
        SCROLL DOWN
      </button>
    </div>
    <div v-if="isDesktop" style="display: flex; flex-direction: column; justify-content: center; margin-top: 200px;">
      <button style="font-size: 16px; padding: 120px; text-decoration: underline; text-align: start;"
        @click="scrollToMissionBlock">
        SCROLL DOWN
      </button>
    </div>

    <div v-if="isMobile" ref="missionBlock" 
      style="height: 70vh; display: flex; flex-direction: column; justify-content: center;">
      <p style="font-size: 40px; padding: 20px;">Our mission is simple yet profound:<br>To create a better world through
        technology.</p>
    </div>
    <div v-if="isTablet" ref="missionBlock" 
      style="height: 80vh; display: flex; flex-direction: column; justify-content: center; margin-top: 400px;">
      <p style="font-size: 60px; padding-inline: 10%;">Our mission is simple yet profound:<br>To create a better world
        through
        technology.</p>
    </div>
    <div v-if="isDesktop" ref="missionBlock" 
      style="height: 70vh; display: flex; flex-direction: column; justify-content: center;">
      <p style="font-size: 96px; padding: 120px;">Our mission is simple yet profound:<br>To create a better world
        through
        technology.</p>
    </div>

    <div v-if="isMobile" 
      style="display: flex; flex-direction: column; margin-top: 200px; justify-content: center;">
      <p style="font-size: 16px; padding: 20px; text-decoration: underline;">FEATURED PROJECTS</p>
    </div>
    <div v-if="isTablet" 
      style="display: flex; flex-direction: column; margin-top: 100px; justify-content: center;">
      <p style="font-size: 16px; padding: 10%; text-decoration: underline;">FEATURED PROJECTS</p>
    </div>
    <div v-if="isDesktop" 
      style="display: flex; flex-direction: column; margin-top: 200px; justify-content: center;">
      <p style="font-size: 16px; padding: 120px; text-decoration: underline;">FEATURED PROJECTS</p>
    </div>


    <div v-if="isMobile" 
      style="display: flex; flex-direction: column; padding-inline: 20px; justify-content: space-between;">
      <p style="font-size: 30px;" @click="openPawhoo">Pawhoo</p>
      <img @click="openPawhoo" :src="require('@/assets/dog2.jpg')" alt="pawhoo"
        style="width: 100%; height: 500px; border-radius: 40px; object-fit: cover; margin-top: 10px;">
    </div>
    <div v-if="isTablet" 
      style="display: flex; padding-inline: 10%; justify-content: space-between;">
      <p style="font-size: 30px; cursor: pointer;" @click="openPawhoo">Pawhoo</p>
      <img @click="openPawhoo" :src="require('@/assets/dog2.jpg')" alt="pawhoo"
        style="width: 80%; height: 700px; border-radius: 40px; object-fit: cover; cursor: pointer;">
    </div>
    <div v-if="isDesktop" 
      style="display: flex; padding-inline: 120px; justify-content: space-between; margin-top: 150px;">
      <p style="font-size: 64px; cursor: pointer;" @click="openPawhoo">Pawhoo</p>
      <img @click="openPawhoo" :src="require('@/assets/dog2.jpg')" alt="pawhoo"
        style="width: 70%; height: 700px; border-radius: 40px; object-fit: cover; cursor: pointer;">
    </div>

    <div v-if="isMobile" 
      style="display: flex; flex-direction: column; padding-inline: 20px; justify-content: space-between; margin-top: 40px;">
      <p style="font-size: 30px;">Exo</p>
      <img :src="require('@/assets/exo.jpg')" alt="exo"
        style="width: 100%; height: 500px; border-radius: 40px; object-fit: cover; margin-top: 10px;">
    </div>
    <div v-if="isTablet" 
      style="display: flex; padding-inline: 10%; justify-content: space-between; margin-top: 150px;">
      <p style="font-size: 30px;">Exo</p>
      <img :src="require('@/assets/exo.jpg')" alt="exo"
        style="width: 80%; height: 700px; border-radius: 40px; object-fit: cover;">
    </div>
    <div v-if="isDesktop" 
      style="display: flex; padding-inline: 120px; justify-content: space-between; margin-top: 150px;">
      <p style="font-size: 64px;">Exo</p>
      <img :src="require('@/assets/exo.jpg')" alt="exo"
        style="width: 70%; height: 700px; border-radius: 40px; object-fit: cover;">
    </div>

    <div v-if="isMobile" 
      style="display: flex; flex-direction: column; padding-inline: 20px; justify-content: space-between; margin-top: 40px;">
      <p style="font-size: 30px;">Solarsage</p>
      <img :src="require('@/assets/solar.jpg')" alt="solarsage"
        style="width: 100%; height: 500px; border-radius: 40px; object-fit: cover; margin-top: 10px;">
    </div>
    <div v-if="isTablet" 
      style="display: flex; padding-inline: 10%; justify-content: space-between; margin-top: 150px;">
      <p style="font-size: 30px; margin-right: 10px;">Solarsage</p>
      <img :src="require('@/assets/solar.jpg')" alt="solarsage"
        style="width: 80%; height: 700px; border-radius: 40px; object-fit: cover;">
    </div>
    <div v-if="isDesktop" 
      style="display: flex; padding-inline: 120px; justify-content: space-between; margin-top: 150px;">
      <p style="font-size: 64px;">Solarsage</p>
      <img :src="require('@/assets/solar.jpg')" alt="solarsage"
        style="width: 70%; height: 700px; border-radius: 40px; object-fit: cover;">
    </div>


    <div v-if="isMobile" ref="contactUs" 
      style="display: flex; flex-direction: column; padding-inline: 20px; margin-top: 200px;">
      <p style="font-size: 30px;">Contact Us</p>
      <img :src="require('@/assets/contactus.png')" alt="pawhoo"
        style="width: 100%; height: 500px; border-radius: 40px; margin-top: 20px; object-fit: cover;">
      <p style="font-size: 16px; margin-top: 30px;">
        Reach out and let's start shaping tomorrow together! Whether you've got a tech challenge
        to conquer or just want to say hello, our team is geared up and waiting to hear from you.
      </p>
      <div style="flex-grow: 1;"></div>
      <button
        style="font-size: 16px; text-decoration: underline; margin-bottom: 20px; margin-top: 40px; text-align: start;"
        @click="openEmailInNewTab">HEY@TACKSTRY.COM</button>
      <button
        style="font-size: 16px; text-decoration: underline; margin-bottom: 20px; margin-top: 10px; text-align: start;"
        @click="openEmailInNewTab">SUPPORT@TACKSTRY.COM</button>
    </div>
    <div v-if="isTablet" ref="contactUs" 
      style="display: flex; padding-inline: 10%; justify-content: space-between; margin-top: 200px;">
      <div style="display: flex; flex-direction: column; margin-right: 40px;">
        <p style="font-size: 30px;">Contact Us</p>
        <p style="font-size: 18px; margin-top: 30px;">
          Reach out and let's start shaping tomorrow together! Whether you've got a tech challenge
          to conquer or just want to say hello, our team is geared up and waiting to hear from you.
        </p>
        <div style="flex-grow: 1;"></div>
        <button style="font-size: 16px; text-decoration: underline; margin-bottom: 20px; text-align: start;"
          @click="openEmailInNewTab">HEY@TACKSTRY.COM</button>
        <button style="font-size: 16px; text-decoration: underline; margin-bottom: 20px; text-align: start;"
          @click="openEmailInNewTab">SUPPORT@TACKSTRY.COM</button>
      </div>
      <img :src="require('@/assets/contactus.png')" alt="pawhoo"
        style="width: 70%; height: 700px; border-radius: 40px; object-fit: cover;">
    </div>
    <div v-if="isDesktop" ref="contactUs" 
      style="display: flex; padding-inline: 120px; justify-content: space-between; margin-top: 200px;">
      <div style="display: flex; flex-direction: column; margin-right: 50px;">
        <p style="font-size: 64px;">Contact Us</p>
        <p style="font-size: 22px; margin-top: 30px;">
          Reach out and let's start shaping tomorrow together! Whether you've got a tech challenge
          to conquer or just want to say hello, our team is geared up and waiting to hear from you.
        </p>
        <div style="flex-grow: 1;"></div>
        <button style="font-size: 16px; text-decoration: underline; margin-bottom: 20px; text-align: start;"
          @click="openEmailInNewTab">HEY@TACKSTRY.COM</button>
        <button style="font-size: 16px; text-decoration: underline; margin-bottom: 20px; text-align: start;"
          @click="openEmailInNewTab">SUPPORT@TACKSTRY.COM</button>
      </div>
      <img :src="require('@/assets/contactus.png')" alt="pawhoo"
        style="width: 70%; height: 700px; border-radius: 40px; object-fit: cover;">
    </div>


    <div v-if="isMobile" style="display: flex; padding-inline: 20px; margin-top: 100px; margin-bottom: 50px;">
      <p>
        © {{ getCurrentYear() }} TACKSTRY. All Rights Reserved.
      </p>
      <div style="flex-grow: 1;"></div>
    </div>
    <div v-if="isTablet" style="display: flex; padding-inline: 10%; margin-top: 200px; margin-bottom: 50px;">
      <p style="font-size: 16px;">
        © {{ getCurrentYear() }} TACKSTRY. All Rights Reserved.
      </p>
      <div style="flex-grow: 1;"></div>
    </div>
    <div v-if="isDesktop" style="display: flex; padding-inline: 120px; margin-top: 200px; margin-bottom: 50px;">
      <p>
        © {{ getCurrentYear() }} TACKSTRY. All Rights Reserved.
      </p>
      <div style="flex-grow: 1;"></div>
    </div>

  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
  data() {
    return {
      isMobile: window.innerWidth < 600,
      isTablet: window.innerWidth > 599 && window.innerWidth < 1400,
      isDesktop: window.innerWidth > 1400,
    }
  },
  mounted() {
    // this.$gtag.event('site visit', { method: 'Google' })
  },
  methods: {
    getCurrentYear() {
      const now = new Date();
      return now.getFullYear();
    },
    openEmailInNewTab() {
      const mailtoAnchor = document.createElement('a');
      mailtoAnchor.href = "mailto:hey@tackstry.com";
      mailtoAnchor.target = '_blank';
      mailtoAnchor.click()
    },
    scrollToInnovationBlock() {
      const element = this.$refs.innovationBlock;
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    },
    scrollToServicesBlock() {
      const element = this.$refs.servicesBlock;
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    scrollToContactBlock() {
      const element = this.$refs.contactUs;
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'end', offsetTop: 100 });
      }
    },
    scrollToMissionBlock() {
      const element = this.$refs.missionBlock;
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    },
    openPawhoo() {
      window.open("https://www.pawhoo.pet", '_blank')
    }
  }
});
</script>

<style>
@font-face {
  font-family: 'DuneRise';
  src: url('~@/assets/dune_rise.otf');
}

.custom-font {
  font-family: 'DuneRise';
}
</style>
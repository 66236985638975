<template>
  <v-app style="background: #222222; font-family: 'Satoshi'; color: white;">
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({

  }),
  mounted() {
    document.title = 'Tackstry - Where Innovation meets Integration';
  }
}


</script>


<style>
@font-face {
  font-family: Satoshi;
  src: url('~@/assets/Satoshi-Medium.otf');
}

body {
  overscroll-behavior: contain; 
  background-color: #333333;
}



</style>